import React, { Component } from "react";

export default class CloudInfraImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg width="406" height="458" viewBox="0 0 406 458" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_41)">
<path d="M155.533 279.994C157.838 284.457 157.405 289.264 154.565 290.731C151.725 292.198 147.554 289.769 145.249 285.307C144.24 283.353 143.756 281.334 143.768 279.558L134.237 260.526L143.264 256.243L151.701 275.46C153.157 276.477 154.524 278.041 155.533 279.994Z" fill="#FFB6B6"/>
<path d="M84.6053 451.718L92.7317 451.717L96.5974 420.372L84.6037 420.373L84.6053 451.718Z" fill="#FFB6B6"/>
<path d="M112.447 451.533C112.7 451.959 112.834 453.335 112.834 453.83C112.834 455.354 111.599 456.588 110.076 456.588H84.9093C83.8701 456.588 83.0277 455.746 83.0277 454.707V453.659C83.0277 453.659 81.7827 450.51 84.3459 446.629C84.3459 446.629 87.5316 449.668 92.2917 444.908L93.6955 442.365L103.857 449.796L109.489 450.489C110.721 450.641 111.813 450.466 112.447 451.533Z" fill="#2F2E41"/>
<path d="M46.4812 451.718L54.6075 451.717L58.4733 420.372L46.4795 420.373L46.4812 451.718Z" fill="#FFB6B6"/>
<path d="M74.323 451.533C74.576 451.959 74.7096 453.335 74.7096 453.83C74.7096 455.354 73.4747 456.588 71.9515 456.588H46.7851C45.746 456.588 44.9035 455.746 44.9035 454.707V453.659C44.9035 453.659 43.6586 450.51 46.2217 446.629C46.2217 446.629 49.4074 449.668 54.1676 444.908L55.5713 442.365L65.7324 449.796L71.3646 450.489C72.5967 450.641 73.6892 450.466 74.323 451.533Z" fill="#2F2E41"/>
<path d="M34.9785 266.777L31.5551 287.698L42.2057 355.406L44.8684 437.188H59.2479L66.55 359.97L65.7893 309L82.9063 437.948H97.849L99.2627 276.287L34.9785 266.777Z" fill="#2F2E41"/>
<path d="M52.8563 158.369H79.7765L103.066 172.063C99.4417 171.141 96.6413 204.771 96.5461 234.486C96.5398 236.46 94.5832 238.73 94.6013 240.659C94.6169 242.321 96.604 243.648 96.6385 245.26C96.6707 246.767 96.7114 248.249 96.7607 249.7C96.8017 250.904 94.5481 252.5 94.6013 253.659C94.6691 255.14 97.0477 256.168 97.136 257.561C97.679 266.127 98.6148 272.913 100.023 276.287C100.023 276.287 39.9234 293.024 35.3589 267.158C35.3589 267.158 35.6195 257.861 35.5829 245.022C35.5797 243.883 34.6096 242.85 34.6013 241.659C34.5951 240.774 35.5519 239.741 35.5426 238.831C35.5349 238.083 34.6113 237.422 34.6013 236.659C34.5953 236.204 35.5036 235.652 35.4967 235.193C35.1317 210.779 33.4925 180.012 27.7513 172.063L52.8563 158.369Z" fill="#6C63FF"/>
<path d="M79.4829 158.369C79.4829 158.369 96.9267 164.708 102.462 169.758C105.403 172.442 125.129 223.034 125.129 223.034L152.526 270.748L138.285 276.158L109.153 232.163L96.2197 203.254L79.4829 158.369Z" fill="#6C63FF"/>
<path d="M43.3013 281.8C45.8455 286.131 45.6738 290.954 42.9178 292.573C40.1618 294.192 35.8651 291.994 33.3209 287.663C32.2072 285.767 31.6141 283.777 31.5301 282.003L20.9798 263.516L29.7604 258.75L39.229 277.48C40.7381 278.417 42.1876 279.904 43.3013 281.8Z" fill="#FFB6B6"/>
<path d="M49.0525 181.953L41.4449 164.455C41.4449 164.455 23.5671 170.161 23.5671 178.529C23.5671 178.529 12.9164 242.433 14.438 258.409L24.3278 275.907L38.4019 264.876L29.6532 244.715L49.0525 181.953Z" fill="#6C63FF"/>
<path d="M33.4067 295.629L42.3804 277.808L52.8563 292.263L43.428 301.392L33.4067 295.629Z" fill="#2F2E41"/>
<path d="M66.0627 153.342C75.6783 153.342 83.4733 145.547 83.4733 135.932C83.4733 126.316 75.6783 118.521 66.0627 118.521C56.4471 118.521 48.6521 126.316 48.6521 135.932C48.6521 145.547 56.4471 153.342 66.0627 153.342Z" fill="#FFB6B6"/>
<path d="M79.6392 116.483C76.6137 115.514 75.1821 111.915 72.4145 110.355C69.3538 108.63 65.6923 107.553 62.3172 108.529C60.2463 109.128 58.4421 110.451 56.3698 111.045C56.0075 111.149 55.6264 111.221 55.2395 111.258C53.0108 111.47 51.0974 112.807 49.9836 114.749L49.9545 114.799C49.3006 115.918 48.3712 116.844 47.5463 117.844C44.5363 121.492 42.9071 126.232 42.9406 130.961C42.9766 136.045 44.9326 141.092 48.3317 144.872L49.2 144.472C51.2983 146.284 53.3966 148.097 55.495 149.909C54.3531 145.789 53.2005 141.524 53.805 137.291C54.4096 133.058 57.256 128.835 61.4529 128.018C63.0406 127.709 64.7151 127.896 66.2563 127.405C67.7975 126.914 69.2171 125.33 68.6884 123.801C69.6427 126.324 73.2023 126.175 75.6522 127.304C81.1357 129.83 79.1284 125.926 83.8884 129.64C85.4962 120.729 91.6551 120.331 79.6392 116.483Z" fill="#2F2E41"/>
<path d="M0 455.986C0 456.646 0.53003 457.176 1.19006 457.176H404.48C405.14 457.176 405.67 456.646 405.67 455.986C405.67 455.326 405.14 454.796 404.48 454.796H1.19006C0.53003 454.796 0 455.326 0 455.986Z" fill="#CCCCCC"/>
<path d="M351.722 457.176H175.297C153.785 457.176 136.284 439.675 136.284 418.162V39.0133C136.284 17.5014 153.785 0 175.297 0H351.722C373.233 0 390.735 17.5014 390.735 39.0133V418.162C390.735 439.675 373.233 457.176 351.722 457.176Z" fill="#3F3D56"/>
<path d="M390.735 139.464C389.825 139.464 389.084 140.204 389.084 141.114V193.928C389.084 194.839 389.825 195.579 390.735 195.579C391.645 195.579 392.385 194.839 392.385 193.928V141.114C392.385 140.204 391.645 139.464 390.735 139.464Z" fill="#3F3D56"/>
<path d="M387.022 39.0136V418.156C387.022 436.88 372.448 452.197 354.021 453.385H354.012C353.74 453.401 353.468 453.418 353.187 453.426C352.7 453.451 352.213 453.459 351.718 453.459C351.718 453.459 349.903 453.327 346.585 453.08C343.235 452.832 338.358 452.461 332.276 451.974C330.452 451.833 328.529 451.677 326.499 451.512C322.456 451.182 318.016 450.818 313.254 450.406C311.356 450.249 309.392 450.076 307.387 449.902C297.138 449.019 285.659 447.988 273.553 446.816C271.506 446.626 269.443 446.428 267.364 446.222C266.819 446.164 266.266 446.115 265.713 446.057C257.081 445.207 139.997 422.579 139.997 418.156V39.0136C139.997 19.5135 155.801 3.71042 175.301 3.71042H196.377C199.744 3.71042 202.451 6.33464 202.74 9.69332C202.764 9.91615 202.789 10.1389 202.83 10.3617C203.441 13.7369 206.536 16.0888 209.968 16.0888H317.051C320.483 16.0888 323.578 13.7369 324.189 10.3617C324.23 10.1389 324.255 9.91615 324.28 9.69332C324.568 6.33464 327.275 3.71042 330.642 3.71042H351.718C371.218 3.71042 387.022 19.5135 387.022 39.0136Z" fill="white"/>
<path d="M135.459 99.8526C134.548 99.8526 133.808 100.593 133.808 101.503V114.707C133.808 115.617 134.548 116.357 135.459 116.357C136.369 116.357 137.109 115.617 137.109 114.707V101.503C137.109 100.593 136.369 99.8526 135.459 99.8526Z" fill="#3F3D56"/>
<path d="M135.459 139.464C134.548 139.464 133.808 140.204 133.808 141.114V166.696C133.808 167.606 134.548 168.346 135.459 168.346C136.369 168.346 137.109 167.606 137.109 166.696V141.114C137.109 140.204 136.369 139.464 135.459 139.464Z" fill="#3F3D56"/>
<path d="M135.459 175.773C134.548 175.773 133.808 176.514 133.808 177.424V203.006C133.808 203.916 134.548 204.656 135.459 204.656C136.369 204.656 137.109 203.916 137.109 203.006V177.424C137.109 176.514 136.369 175.773 135.459 175.773Z" fill="#3F3D56"/>
<path d="M195.803 9.49023H164.239C164.068 9.49023 163.929 9.62912 163.929 9.80044V13.3062C163.929 13.4775 164.068 13.6164 164.239 13.6164H195.803C195.974 13.6164 196.113 13.4775 196.113 13.3062V9.80044C196.113 9.62912 195.974 9.49023 195.803 9.49023Z" fill="#E6E6E6"/>
<path d="M335.857 13.6164C337.68 13.6164 339.158 12.1385 339.158 10.3155C339.158 8.49242 337.68 7.01456 335.857 7.01456C334.034 7.01456 332.556 8.49242 332.556 10.3155C332.556 12.1385 334.034 13.6164 335.857 13.6164Z" fill="#E6E6E6"/>
<path d="M344.935 13.6164C346.758 13.6164 348.236 12.1385 348.236 10.3155C348.236 8.49242 346.758 7.01456 344.935 7.01456C343.112 7.01456 341.634 8.49242 341.634 10.3155C341.634 12.1385 343.112 13.6164 344.935 13.6164Z" fill="#E6E6E6"/>
<path d="M354.012 13.6164C355.835 13.6164 357.313 12.1385 357.313 10.3155C357.313 8.49242 355.835 7.01456 354.012 7.01456C352.189 7.01456 350.711 8.49242 350.711 10.3155C350.711 12.1385 352.189 13.6164 354.012 13.6164Z" fill="#E6E6E6"/>
<path d="M333.509 95.159H193.51C191.304 95.159 189.51 93.3645 189.51 91.159C189.51 88.9534 191.304 87.159 193.51 87.159H333.509C335.715 87.159 337.509 88.9534 337.509 91.159C337.509 93.3645 335.715 95.159 333.509 95.159Z" fill="#E6E6E6"/>
<path d="M357.05 411.659H305.05C302.844 411.659 301.05 409.865 301.05 407.659C301.05 405.453 302.844 403.659 305.05 403.659H357.05C359.255 403.659 361.05 405.453 361.05 407.659C361.05 409.865 359.255 411.659 357.05 411.659Z" fill="#E6E6E6"/>
<path d="M218.05 411.659H166.05C163.844 411.659 162.05 409.865 162.05 407.659C162.05 405.453 163.844 403.659 166.05 403.659H218.05C220.255 403.659 222.05 405.453 222.05 407.659C222.05 409.865 220.255 411.659 218.05 411.659Z" fill="#E6E6E6"/>
<path d="M361.05 105.907H165.969V106.907H361.05V105.907Z" fill="#3F3D56"/>
<path d="M333.509 156.159H193.51C191.304 156.159 189.51 154.365 189.51 152.159C189.51 149.953 191.304 148.159 193.51 148.159H333.509C335.715 148.159 337.509 149.953 337.509 152.159C337.509 154.365 335.715 156.159 333.509 156.159Z" fill="#E6E6E6"/>
<path d="M361.05 166.907H165.969V167.907H361.05V166.907Z" fill="#3F3D56"/>
<path d="M333.509 217.159H193.51C191.304 217.159 189.51 215.365 189.51 213.159C189.51 210.953 191.304 209.159 193.51 209.159H333.509C335.715 209.159 337.509 210.953 337.509 213.159C337.509 215.365 335.715 217.159 333.509 217.159Z" fill="#E6E6E6"/>
<path d="M361.05 227.907H165.969V228.907H361.05V227.907Z" fill="#3F3D56"/>
<path d="M361.05 279.907H165.969V280.907H361.05V279.907Z" fill="#3F3D56"/>
<path d="M361.05 331.907H165.969V332.907H361.05V331.907Z" fill="#3F3D56"/>
<path d="M264.882 368.486C296.915 368.486 322.882 342.518 322.882 310.486C322.882 278.453 296.915 252.486 264.882 252.486C232.85 252.486 206.882 278.453 206.882 310.486C206.882 342.518 232.85 368.486 264.882 368.486Z" fill="#6C63FF"/>
</g>
<defs>
<clipPath id="clip0_1_41">
<rect width="405.67" height="457.176" fill="white"/>
</clipPath>
</defs>
</svg>

    );
  }
}
